body.ReactModal__Body--open {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}
.ReactModal__Content {
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-width: 360px;

  @media (max-width: 767px) {
    min-width: 312px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
